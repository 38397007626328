import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TableToExcel from "table-to-excel";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
  Select,
  Box,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import OrdersTable from "./OrdersTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import UpdateShippingCompanyModal from "./actions/UpdateShippingCompanyModal";
import OrderStatusModal from "./actions/OrderStatusModal";
import UpdateOrdersStatusModal from "./actions/UpdateOrdersStatusModal";
import Filter from "./actions/Filter";

import {
  BiSolidShoppingBags,
  BiHourglass,
  BiPackage,
  BiCheckCircle,
  BiRefresh,
  BiTimeFive,
  BiUndo,
  BiArrowBack,
  BiXCircle,
  BiArchiveIn,
  BiArchiveOut,
} from "react-icons/bi";
import { getOrders } from "../../../store/orders/ordersSlice";

import { OrdersWrapper } from "./OrdersStyle";

import theme from "../../global/theme";
import InputSearch from "../../shared/inputSearch/InputSearch";
import OrderNotes from "./OrderNotes";
import OrderProducts from "./OrderProducts";

const tableToExcel = new TableToExcel();
const OrderCard = ({
  href,
  bg,
  color,
  icon,
  iconColor,
  orderCount,
  orderLabel,
  status,
  setCurrentStatus,
}) => (
  <Flex
    boxShadow="md"
    padding={{ base: 3, xl: 6 }}
    alignItems="center"
    bgColor={bg}
    color={color}
    as="button"
    onClick={() => {
      setCurrentStatus(status);
    }}
    justifyContent="space-between"
    className="transition-shadow shadow-xl rounded-xl hover:shadow-md"
  >
    <Flex justifyContent="space-between">
      <Box color={iconColor}>{React.createElement(icon, { size: 40 })}</Box>
    </Flex>
    <Box>
      <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
        {orderCount}
      </Text>
      <Text
        fontWeight="bold"
        fontSize={{ base: 12, xl: 15 }}
        textTransform="capitalize"
      >
        {orderLabel}
      </Text>
    </Box>
  </Flex>
);
const Orders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const auth = useSelector((state) => state.auth);

  const [searchParams, setSearchParams] = useSearchParams();
  const createOrderBtnRef = useRef();

  const page = +searchParams.get("page") || 1;
  const size = +searchParams.get("size") || 20;
  const query = searchParams.get("query") || "";

  const [currentStatus, setCurrentStatus] = useState(
    searchParams.get("status") || ""
  );

  const [filter, setFilter] = useState(JSON.stringify({})); // {});
  const [additionalParams, setAdditionalParams] = useState({});

  const [showCreateOrder, setShowCreateOrder] = useState(false);
  const [showUpdateOrder, setShowUpdateOrder] = useState(false);
  const [showOrderNotes, setShowOrderNotes] = useState(false);
  const [showOrderProducts, setShowOrderProducts] = useState(false);
  const [showUpdateOrdersShippingCompany, setShowUpdateOrdersShippingCompany] =
    useState(false);
  const [showOrderStatus, setShowOrderStatus] = useState(false);

  const [updateOrders, setUpdateOrders] = useState([]);
  const [showUpdateOrdersStatus, setShowUpdateOrdersStatus] = useState(false);
  const [showReverseOrdersStatus, setShowReverseOrdersStatus] = useState(false);

  useEffect(() => {
    if (
      searchParams.get("open_modal") &&
      searchParams.get("open_modal") === "true"
    ) {
      createOrderBtnRef.current.click();
      searchParams.delete("open_modal");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);
  useEffect(() => {
    // Using object spread to conditionally add 'status' property
    searchParams.set("status", currentStatus);
    setSearchParams(searchParams);
    const filter = JSON.stringify({
      ...(additionalParams || {}),
      ...(currentStatus ? { status: currentStatus } : {}),
    });

    setFilter(filter);
  }, [currentStatus, additionalParams]);
  useEffect(() => {
    dispatch(getOrders({ page, query, size, filter }));
  }, [dispatch, page, query, size, filter]);
  const cards = [
    {
      status: "",
      bg: !currentStatus ? "#539884" : "#FFB0B0",
      icon: BiSolidShoppingBags,
      iconColor: "#1C3FAA",
      orderCount: orders.data?.allOrders,
      orderLabel: "اجمالي الطلبات",
      color: !currentStatus ? "#FFB0B0" : "#539884",
    },
    {
      status: `pending`,
      bg: currentStatus === "pending" ? "#539884" : "#FC6736",
      icon: BiHourglass,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.pendingOrders,
      orderLabel: "طلبات معلقة",
      color: "#EFECEC",
    },
    {
      status: `preparing`,
      bg: currentStatus === "preparing" ? "#539884" : "#FC6736",
      icon: BiPackage,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.preparingOrders,
      orderLabel: "طلبات جار التحضير",
      color: "#EFECEC",
    },
    {
      status: `shipped`,
      bg: currentStatus === "shipped" ? "#539884" : "#FC6736",
      icon: BiCheckCircle,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.shippedOrders,
      orderLabel: "طلبات تم الشحن",
      color: "#EFECEC",
    },
    {
      status: `available`,
      bg: currentStatus === "available" ? "#539884" : "#FC6736",
      icon: BiCheckCircle,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.availableOrders,
      orderLabel: "طلبات تم التوصيل",
      color: "#EFECEC",
    },
    {
      status: `skip`,
      bg: currentStatus === "skip" ? "#539884" : "#0C2D57",
      icon: BiRefresh,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.skipOrders,
      orderLabel: "جار الاسترجاع",
      color: "#EFECEC",
    },
    {
      status: `holding`,
      bg: currentStatus === "holding" ? "#539884" : "#0C2D57",
      icon: BiTimeFive,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.holdingOrders,
      orderLabel: "طلبات مؤجله",
      color: "#EFECEC",
    },
    {
      status: `ask_to_return`,
      bg: currentStatus === "ask_to_return" ? "#539884" : "#0C2D57",
      icon: BiUndo,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.asToReturnOrders,
      orderLabel: "طلب استرجاع من العميل",
      color: "#EFECEC",
    },
    {
      status: `returned1`,
      bg: currentStatus === "returned1" ? "#539884" : "#0C2D57",
      icon: BiArrowBack,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.returned1Orders,
      orderLabel: "طلبات مرتجعة",
      color: "#EFECEC",
    },
    {
      status: `returned2`,
      bg: currentStatus === "returned2" ? "#539884" : "#0C2D57",
      icon: BiArchiveIn,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.returned2Orders,
      orderLabel: "طلبات مرتجعة بعد التسليم",
      color: "#EFECEC",
    },
    {
      status: `declined1`,
      bg: currentStatus === "declined1" ? "#539884" : "#0C2D57",
      icon: BiXCircle,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.declined1Orders,
      orderLabel: "طلبات ملغية",
      color: "#EFECEC",
    },
    {
      status: `declined2`,
      bg: currentStatus === "declined2" ? "#539884" : "#0C2D57",
      icon: BiArchiveOut,
      iconColor: "#FFB0B0",
      orderCount: orders.data?.declined2Orders,
      orderLabel: "طلبات ملغية اثناء التحضير",
      color: "#EFECEC",
    },
  ];
  return (
    <OrdersWrapper>
      <Breadcrumbs
        currentPage={t("pages.orders.orders")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {orders.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {orders.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ base: 2, lg: 4, xl: 5 }} spacing={4} mb={8}>
        {" "}
        {cards.map((card, index) => (
          <OrderCard
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
            key={index}
            {...card}
          />
        ))}
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex mb={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateOrder(true)}
            ref={createOrderBtnRef}
          >
            انشاء طلب +
          </Button>

          <Button
            type="button"
            variant="outline"
            onClick={() => {
              document.querySelectorAll(".removable").forEach((el) => {
                el.remove();
              });
              tableToExcel.render("table-to-xls_orders");
            }}
          >
            استخراج شيت اكسل
          </Button>
        </Flex>

        <Flex marginBottom={8} alignItems="center" justifyContent="flex-end">
          <InputSearch
            isLoading={orders.isLoading}
            handleSearch={(query) => {
              searchParams.set("query", query);
              setSearchParams(searchParams);
            }}
          />
        </Flex>
      </SimpleGrid>

      <Filter
        handleFilter={(values) => {
          setAdditionalParams({ ...additionalParams, ...values });
        }}
        handleClearFilter={() => {
          setAdditionalParams({});
        }}
        disablesBtns={orders.isLoading}
      />

      {updateOrders.length > 0 && (
        <Flex justifyContent="flex-end">
          {auth.user?.rule?.permissions?.includes("manage_orders") &&
            auth.user?.rule?.name === "superadmin" &&
            auth.user?.role === "admin" && (
              <Button
                variant="outline"
                type="button"
                marginLeft={4}
                ms={4}
                color={theme.error}
                borderColor={theme.error}
                onClick={() => {
                  setShowReverseOrdersStatus(true);
                }}
              >
                إرجاع حالة الطلبات
              </Button>
            )}

          <Button
            bg="red.600"
            mb={6}
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            color="white"
            _hover={{ bg: "green.600" }}
            onClick={() => setShowUpdateOrdersStatus(true)}
          >
            تحديث حالة الطلبات
          </Button>

          <Button
            bg="blue.600"
            mb={6}
            textTransform="uppercase"
            ms={4}
            fontWeight="bold"
            fontSize={18}
            color="white"
            _hover={{ bg: "blue.600" }}
            onClick={() => setShowUpdateOrdersShippingCompany(true)}
          >
            شركة الشحن
          </Button>
        </Flex>
      )}

      {orders.data?.itemsCount > 0 ? (
        <>
          <OrdersTable
            data={orders.data.data}
            page={page}
            handleUpdate={(el) => setShowUpdateOrder(el)}
            handleOrderStatus={(el) => setShowOrderStatus(el)}
            setShowOrderProducts={(el) => setShowOrderProducts(el)}
            handleSelectOrder={(el, e) => {
              if (e.target.checked) {
                setUpdateOrders([...updateOrders, el]);
              } else {
                const myOrders = updateOrders;
                const orders = myOrders.filter((o) => o._id !== el._id);
                setUpdateOrders(orders);
              }
            }}
            handleSelectAllOrders={() => {
              const allChecks = document.querySelectorAll(".order-check");
              allChecks.forEach((el) => {
                el.checked = true;
              });

              const allOrders = orders.data?.data ?? [];
              setUpdateOrders(allOrders);
            }}
            setShowOrderNotes={(el) => setShowOrderNotes(el)}
          />
          <OrdersTable
            data={orders.data.data}
            showDetails={true}
            page={page}
            handleUpdate={(el) => setShowUpdateOrder(el)}
            handleOrderStatus={(el) => setShowOrderStatus(el)}
            setShowOrderProducts={(el) => setShowOrderProducts(el)}
            handleSelectOrder={(el, e) => {
              if (e.target.checked) {
                setUpdateOrders([...updateOrders, el]);
              } else {
                const myOrders = updateOrders;
                const orders = myOrders.filter((o) => o._id !== el._id);
                setUpdateOrders(orders);
              }
            }}
            handleSelectAllOrders={() => {
              const allChecks = document.querySelectorAll(".order-check");
              allChecks.forEach((el) => {
                el.checked = true;
              });

              const allOrders = orders.data?.data ?? [];
              setUpdateOrders(allOrders);
            }}
            setShowOrderNotes={(el) => setShowOrderNotes(el)}
          />
        </>
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.orders.no_orders")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="space-between" alignItems="center">
        <Pagination
          page={+page}
          size={+size}
          itemsCount={orders.data?.itemsCount ?? 0}
          onChange={(page) => {
            searchParams.set("page", page);
            setSearchParams(searchParams);
          }}
        />
        <Select
          w="auto"
          mt="1.5rem"
          defaultValue={size}
          onChange={(e) => {
            searchParams.set("size", e.target.value);
            setSearchParams(searchParams);
          }}
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
        </Select>
      </Flex>

      {showCreateOrder && (
        <CreateModal onClose={() => setShowCreateOrder(false)} />
      )}
      {showUpdateOrder && (
        <UpdateModal
          data={showUpdateOrder}
          onClose={() => setShowUpdateOrder(false)}
        />
      )}
      {showUpdateOrdersShippingCompany && (
        <UpdateShippingCompanyModal
          data={updateOrders}
          onClose={() => setShowUpdateOrdersShippingCompany(false)}
        />
      )}
      {showOrderStatus && (
        <OrderStatusModal
          data={showOrderStatus}
          onClose={() => setShowOrderStatus(false)}
        />
      )}
      {showUpdateOrdersStatus && (
        <UpdateOrdersStatusModal
          data={updateOrders}
          onClose={() => setShowUpdateOrdersStatus(false)}
        />
      )}
      {showReverseOrdersStatus && (
        <UpdateOrdersStatusModal
          data={updateOrders}
          reverse={true}
          onClose={() => setShowReverseOrdersStatus(false)}
        />
      )}
      {showOrderNotes && (
        <OrderNotes
          data={showOrderNotes}
          onClose={() => {
            setShowOrderNotes(false);
            dispatch(getOrders({ page, query, filter, size }));
          }}
        />
      )}
      {showOrderProducts && (
        <OrderProducts
          data={showOrderProducts}
          onClose={() => {
            setShowOrderProducts(null);
          }}
        />
      )}
    </OrdersWrapper>
  );
};

export default Orders;
