import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Heading,
  Text,
  Image,
  CircularProgress,
  Accordion,
  AccordionItem,
  Link as ChakraLink,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

import { toggleSidebar } from "../../../store/sidebar/sidebarSlice";
import { FcStatistics } from "react-icons/fc";
import systemBg from "../../../assets/images/logo.png";

import theme from "../../global/theme";

import { SidebarOverlay, SidebarWrapper } from "./SidebarStyle";

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sidebar = useSelector((state) => state.sidebar);
  const auth = useSelector((state) => state.auth);

  const closeSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      {sidebar.isNotOpened && (
        <SidebarOverlay
          as={Box}
          className="sidebar-overlay"
          position="fixed"
          top="0"
          right="0"
          w="100%"
          h="100vh"
          bg="rgba(0, 0, 0, .5)"
          zIndex="99"
          role="button"
          onClick={() => dispatch(toggleSidebar())}
        ></SidebarOverlay>
      )}
      <SidebarWrapper className={sidebar.isNotOpened ? "active" : ""}>
        {auth.isLoading ? (
          <Flex h="100vh" alignItems="center" justifyContent="center">
            <CircularProgress isIndeterminate color={theme.text} />
          </Flex>
        ) : (
          <>
            <Flex alignItems="center" justifyContent="center" mb={8}>
              <Image w="80px" src={systemBg} />
              {/* <Heading
                // letterSpacing={8}
                className="brand-text"
                textTransform="uppercase"
                color="white"
                size="lg"
              >
                {t("general.system_name")}
              </Heading> */}
            </Flex>

            {/* <Text
              textTransform="capitalize"
              mb={4}
              className="text"
              fontSize={16}
              color="#5e76a1"
              fontWeight="600"
              mt={6}
            >
              {t("layout.sidebar.profile")}
            </Text>

            <ChakraLink as={Link} to="profile" className="sidebar-profile">
              <Flex alignItems="center" mb={8}>
                <Avatar size="sm" name={auth.user?.name}>
                  <AvatarBadge
                    borderColor="transparent"
                    boxSize="10px"
                    bg="green.500"
                  />
                </Avatar>
                <Box>
                  <Text
                    marginInline={3}
                    textTransform="capitalize"
                    fontSize={14}
                    fontWeight="600"
                    color="white"
                  >
                    {auth.user?.name && auth.user.name.slice(0, 10)}
                  </Text>
                  <Text
                    marginInline={3}
                    textTransform="capitalize"
                    fontSize={12}
                    color="#5e76a1"
                    fontWeight="600"
                  >
                    {auth.user.role}
                  </Text>
                </Box>
              </Flex>
            </ChakraLink> */}
            <Text
              textTransform="capitalize"
              mb={4}
              className="text"
              fontSize={16}
              color="#5e76a1"
              fontWeight="600"
            >
              {t("layout.sidebar.navigation")}
            </Text>

            <Box className="sidebar-links">
              <Accordion allowToggle={true} border="none">
                <AccordionItem border="none">
                  <NavLink
                    to="/"
                    end
                    onClick={closeSidebar}
                    className="chakra-accordion__button"
                  >
                    <Flex alignItems="center">
                      <span className="icon">
                        <AiIcons.AiTwotoneHome />
                      </span>
                      <span className="text">
                        {t("layout.sidebar.dashboard")}
                      </span>
                    </Flex>
                  </NavLink>
                </AccordionItem>

                {auth.user.role === "admin" &&
                  auth.user?.rule?.permissions?.includes("manage_settings") && (
                    <>
                      <AccordionItem border="none">
                        <NavLink
                          to="statistics"
                          onClick={closeSidebar}
                          className="chakra-accordion__button "
                        >
                          <Flex alignItems="center">
                            <span className="icon">
                              <FcStatistics />
                            </span>
                            <span className="text">الإحصائيات</span>
                          </Flex>
                        </NavLink>
                      </AccordionItem>
                      <AccordionItem border="none">
                        <NavLink
                          to="rules"
                          onClick={closeSidebar}
                          className="chakra-accordion__button "
                        >
                          <Flex alignItems="center">
                            <span className="icon">
                              <FiIcons.FiDisc />
                            </span>
                            <span className="text">
                              {t("layout.sidebar.rules")}
                            </span>
                          </Flex>
                        </NavLink>
                      </AccordionItem>

                      <AccordionItem border="none">
                        <NavLink
                          to="categories"
                          onClick={closeSidebar}
                          className="chakra-accordion__button"
                        >
                          <Flex alignItems="center">
                            <span className="icon">
                              <FiIcons.FiAperture />
                            </span>
                            <span className="text">
                              {t("layout.sidebar.categories")}
                            </span>
                          </Flex>
                        </NavLink>
                      </AccordionItem>

                      <AccordionItem border="none">
                        <NavLink
                          to="shipping_companies"
                          onClick={closeSidebar}
                          className="chakra-accordion__button"
                        >
                          <Flex alignItems="center">
                            <span className="icon">
                              <FiIcons.FiTruck />
                            </span>
                            <span className="text">
                              {t("layout.sidebar.shipping_companies")}
                            </span>
                          </Flex>
                        </NavLink>
                      </AccordionItem>
                    </>
                  )}

                <AccordionItem border="none">
                  <NavLink
                    to="price_list"
                    onClick={closeSidebar}
                    className="chakra-accordion__button"
                  >
                    <Flex alignItems="center">
                      <span className="icon">
                        <FiIcons.FiDollarSign />
                      </span>
                      <span className="text">
                        {t("layout.sidebar.price_list")}
                      </span>
                    </Flex>
                  </NavLink>
                </AccordionItem>

                {auth.user.role === "admin" &&
                  auth.user?.rule?.permissions?.includes("manage_users") && (
                    <AccordionItem border="none">
                      <NavLink
                        to="users"
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiUsers />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.users")}
                          </span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>
                  )}

                {auth.user.role === "admin" &&
                  auth.user?.rule?.permissions?.includes(
                    "manage_warehouses"
                  ) && (
                    <AccordionItem border="none">
                      <NavLink
                        to="warehouses"
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiLayers />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.warehouses")}
                          </span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>
                  )}

                {auth.user.role === "admin" &&
                  auth.user?.rule?.permissions?.includes("manage_products") && (
                    <AccordionItem border="none">
                      <NavLink
                        to="products"
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiShoppingCart />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.products")}
                          </span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>
                  )}

                <AccordionItem border="none">
                  <NavLink
                    to="available_products"
                    onClick={closeSidebar}
                    className="chakra-accordion__button"
                  >
                    <Flex alignItems="center">
                      <span className="icon">
                        <FiIcons.FiShoppingCart />
                      </span>
                      <span className="text">
                        {t("layout.sidebar.available_products")}
                      </span>
                    </Flex>
                  </NavLink>
                </AccordionItem>
                {/* <AccordionItem border="none">
                  <NavLink
                    to="favorite_products"
                    onClick={closeSidebar}
                    className="chakra-accordion__button"
                  >
                    <Flex alignItems="center">
                      <span className="icon">
                        <MdFavoriteBorder />
                      </span>
                      <span className="text">المنتجات المفضلة</span>
                    </Flex>
                  </NavLink>
                </AccordionItem> */}

                {auth.user.role === "merchant" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="my_products"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center">
                        <span className="icon">
                          <FiIcons.FiShoppingBag />
                        </span>
                        <span className="text">
                          {t("layout.sidebar.my_products")}
                        </span>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}

                {auth.user.role === "marketer" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="moderators"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiUser />
                          </span>
                          <span className="text">مودريتور</span>
                        </Flex>
                        <Box
                          color="white"
                          bg="red.700"
                          paddingInline={2}
                          paddingBlock={1}
                          borderRadius="5px"
                        >
                          جديد
                        </Box>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}

                {auth.user.role === "marketer" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="excel_orders"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                          <span className="icon">
                            <MdIcons.MdAddShoppingCart />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.excel_orders")}
                          </span>
                        </Flex>
                        <Box
                          color="white"
                          bg="red.700"
                          paddingInline={2}
                          paddingBlock={1}
                          borderRadius="5px"
                        >
                          جديد
                        </Box>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}

                {auth.user.role !== "merchant" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="orders?open_modal=true"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center">
                        <span className="icon">
                          <MdIcons.MdAddShoppingCart />
                        </span>
                        <span className="text">
                          {t("layout.sidebar.create_order")}
                        </span>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}

                <AccordionItem border="none">
                  <NavLink
                    to="orders?page=1&size=20&query="
                    onClick={closeSidebar}
                    className="chakra-accordion__button"
                  >
                    <Flex alignItems="center">
                      <span className="icon">
                        <FiIcons.FiShoppingBag />
                      </span>
                      <span className="text">{t("layout.sidebar.orders")}</span>
                    </Flex>
                  </NavLink>
                </AccordionItem>

                {auth.user.role === "admin" &&
                  auth.user?.rule?.permissions?.includes("manage_accounts") && (
                    <AccordionItem border="none">
                      <NavLink
                        to="marketers_accounts"
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiDollarSign />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.marketers_accounts")}
                          </span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>
                  )}

                {auth.user.role === "admin" &&
                  auth.user?.rule?.permissions?.includes("manage_accounts") && (
                    <AccordionItem border="none">
                      <NavLink
                        to="merchants_accounts"
                        onClick={closeSidebar}
                        className="chakra-accordion__button"
                      >
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiDollarSign />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.merchants_accounts")}
                          </span>
                        </Flex>
                      </NavLink>
                    </AccordionItem>
                  )}

                {(auth.user.role === "marketer" ||
                  auth.user.role === "merchant" ||
                  auth.user?.rule?.permissions?.includes(
                    "manage_requests"
                  )) && (
                  <AccordionItem border="none">
                    <NavLink
                      to="requests"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center">
                        <span className="icon">
                          <FiIcons.FiDollarSign />
                        </span>
                        <span className="text">
                          {t("layout.sidebar.requests")}
                        </span>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}

                {auth.user.role !== "moderator" && (
                  <AccordionItem border="none">
                    <NavLink
                      to="supports"
                      onClick={closeSidebar}
                      className="chakra-accordion__button"
                    >
                      <Flex alignItems="center">
                        <Flex alignItems="center">
                          <span className="icon">
                            <FiIcons.FiMessageCircle />
                          </span>
                          <span className="text">
                            {t("layout.sidebar.supports")}
                          </span>
                        </Flex>
                      </Flex>
                    </NavLink>
                  </AccordionItem>
                )}
              </Accordion>
            </Box>
          </>
        )}
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
