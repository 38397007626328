import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

let initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_notifications?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateNotification = createAsyncThunk(
  "notifications/updateNotification",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_notification/${args._id}`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "notifications/updateNotifications",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        "/api/update_notifications",
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: {
    // get notifications
    [getNotifications.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getNotifications.rejected]: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.errors = action.payload.errors;
    },

    // update notification
    [updateNotification.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateNotification.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(el => el._id === action.payload.data._id);
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateNotification.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update notifications
    [updateNotifications.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateNotifications.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
    },
    [updateNotifications.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    }
  }
});


export default notificationsSlice.reducer;