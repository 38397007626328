import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SimpleGrid, Box, Image, Text, Flex } from "@chakra-ui/react";
import { FiInfo, FiExternalLink } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineFavorite, MdFavoriteBorder } from "react-icons/md";

import {
  addFavoriteProduct,
  removeFavoriteProduct,
} from "../../../../store/products/productsSlice";

const AvailableList = ({ data }) => {
  const { t } = useTranslation();
  const sidebar = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();

  const getTotalQuantity = (item) => {
    let total = 0;
    for (let i = 0; i < item.properties.length; i++) {
      total += item.properties[i]?.value;
    }
    return total;
  };

  return (
    <SimpleGrid
      className="bg-[#EFECEC] p-2 md:p-4 rounded-xl shadow-lg gap-4 md:gap-8"
      columns={{ base: 2, sm: 2, md: 3, lg: sidebar.isNotOpened ? 4 : 3 }}
    >
      {data.map((el) => (
        <Box
          key={el._id}
          className="border  border-[#0C2D57] rounded-lg overflow-hidden shadow-md bg-white transition-shadow hover:shadow-lg relative"
        >
          <Flex
            as={Link}
            to={`/available_products/${el._id}`}
            className="h-[200px] md:h-[280px] bg-[#f5f5f5] rounded-t-lg overflow-hidden"
          >
            <Image
              src={`/products/${el.image}`}
              alt={el.name}
              className="object-cover w-full h-full"
            />
          </Flex>
          <div className="absolute px-2 py-1 text-[10px] sm:text-xs font-semibold text-white bg-red-500 rounded left-2 top-2">
            عمولة 70 جنيه
          </div>
          <div className="absolute top-2 right-2">
            <div className="flex items-center justify-center p-1 rounded-full bg-black/15 hover:bg-black/30">
              {el.isFavorite ? (
                <MdOutlineFavorite
                  style={{ cursor: "pointer", color: "red" }}
                  size={35}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from propagating to the parent Flex
                    dispatch(removeFavoriteProduct({ productId: el._id }));
                  }}
                />
              ) : (
                <MdFavoriteBorder
                  style={{ cursor: "pointer", color: "red" }}
                  size={30}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from propagating to the parent Flex
                    dispatch(addFavoriteProduct({ productId: el._id }));
                  }}
                />
              )}
            </div>
          </div>

          <Box className="p-2 md:p-4">
            <div className="text-[#0C2D57] text-center text-md sm:text-lg md:text-xl mb-4 capitalize">
              {el.name}
            </div>

            <div className="flex justify-between items-center mt-2 font-medium text-[#0C2D57] text-xs sm:text-sm md:text-base">
              <Text>{t("pages.products.barcode")}:</Text>
              <Text>{el.barcode}</Text>
            </div>

            <div className="flex justify-between items-center mt-2 font-medium text-[#0C2D57] text-xs sm:text-sm md:text-base">
              <Text>الكمية:</Text>
              <Text>{getTotalQuantity(el)}</Text>
            </div>

            <div className="flex justify-between items-center mt-2 font-medium text-[#0C2D57] text-xs sm:text-sm md:text-base">
              <Text>{t("pages.products.sale_price")}:</Text>
              <Text>{el.sale_price}</Text>
            </div>

            <Flex
              className="flex-col gap-4 mt-4 md:flex-row"
              direction={{ base: "column", md: "row" }}
            >
              <Link
                to={`/available_products/${el._id}`}
                className="bg-[#0C2D57] text-white gap-1 flex items-center justify-center w-full py-2 px-4 rounded-md text-xs sm:text-sm md:text-base font-semibold hover:bg-[#0A1F37] transition-colors"
              >
                <FiInfo className="mr-2" />
                {t("general.details")}
              </Link>

              <a
                href={el.media_url}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#FC6736] text-white gap-1 flex items-center justify-center w-full py-2 px-4 rounded-md text-xs sm:text-sm md:text-base font-semibold hover:bg-[#e34f28] transition-colors"
              >
                <FiExternalLink className="mr-2" />
                {t("pages.products.media_url")}
              </a>
            </Flex>
          </Box>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default AvailableList;
