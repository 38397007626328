import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Box,
  Divider,
  Image,
  Link,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useToast,
  Flex,
} from "@chakra-ui/react";

import Breadcrumbs from "../../../shared/breadcrumbs/Breadcrumbs";
import { addToCart } from "../../../../store/cart/cartSlice";
import { getProductById } from "../../../../store/products/productsSlice";

import theme from "../../../global/theme";

const ProductDetails = () => {
  const { t } = useTranslation();
  const toast = useToast({
    status: "success",
    position: "top",
    duration: 800,
  });
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const products = useSelector((state) => state.products);
  const [selectedList, setSelectedList] = useState([]);
  const cart = useSelector((state) => state.cart);
  const { productId } = useParams();
  const [view, setView] = useState("description");

  useEffect(() => {
    dispatch(getProductById({ productId }));
  }, [dispatch, productId]);

  const product = products.product;

  return (
    <div>
      <Breadcrumbs
        currentPage={t("pages.products.products")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {!product ? (
        <Text>please wait ....</Text>
      ) : (
        <>
          <Divider mt={6} borderColor={theme.gary1} />
          <div className="py-8 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
              <div className="flex flex-col -mx-4 md:flex-row">
                <div className="px-4 md:flex-1">
                  <div className="h-[460px] rounded-lg bg-gray-300 dark:bg-gray-700 mb-4">
                    <img
                      className="object-contain h-full mx-auto selection:w-full"
                      src={`/products/${product?.image}`}
                      alt="Product Image"
                    />
                  </div>
                  <div className="flex justify-center mb-4 -mx-2">
                    <div className="w-1/2 px-2">
                      <Link
                        href={product?.media_url}
                        bg={theme.blue}
                        d="block"
                        w="100%"
                        p=".3rem 1rem"
                        borderRadius="5px"
                        mt={3}
                        color={theme.light}
                        textAlign="center"
                      >
                        {t("pages.products.media_url")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="px-4 md:flex-1">
                  <h2 className="mb-4 text-2xl font-extrabold text-gray-800 dark:text-white">
                    {product?.name}
                  </h2>

                  <Text mb={4} fontWeight="bold" color={theme.error}>
                    <Box as="span" color={theme.blue}>
                      ملاحظة:{" "}
                    </Box>
                    {product?.note}
                  </Text>

                  <p className="flex items-center mb-4 text-sm text-gray-700 dark:text-gray-300">
                    الكود: {product?.barcode}
                    <CopyToClipboard
                      text={product?.barcode}
                      onCopy={() => {
                        toast({
                          position: "top",
                          description: "تم نسخ كود المنتج",
                        });
                      }}
                    >
                      <Box
                        className="text-blue-500 transition-colors duration-200 cursor-pointer hover:text-blue-700 ms-2"
                        as="span"
                      >
                        اضغط للنسخ
                      </Box>
                    </CopyToClipboard>
                  </p>

                  <div className="flex items-center mb-4">
                    <div className="text-lg text-gray-600 dark:text-gray-300">
                      {auth.user.role === "merchant" ? (
                        <>سعر التكلفة: {product?.purchase_price} EGP</>
                      ) : (
                        <>سعر البيع: {product?.sale_price} EGP</>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-4 mb-6">
                    {product?.properties
                      ?.filter((prop) => prop.key && prop.value > 1)
                      .map((prop, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-3"
                        >
                          <button
                            onClick={() =>
                              setSelectedList([{ ...prop, quantity: 1 }])
                            }
                            className={`px-5 py-2 rounded-lg font-semibold transition-colors duration-300 ease-in-out transform hover:scale-105 focus:outline-none ${
                              selectedList.some((item) => item.key === prop.key)
                                ? "bg-blue-800 text-white shadow-lg"
                                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                            }`}
                          >
                            {prop.key}
                          </button>
                        </div>
                      ))}
                  </div>

                  {selectedList.length > 0 && auth.user.role !== "merchant" && (
                    <div className="flex justify-center mt-8">
                      <div className="flex items-center gap-3 p-4 space-x-4 bg-white border border-gray-200 rounded-lg shadow-lg">
                        <button
                          onClick={() =>
                            setSelectedList((prev) =>
                              prev.map((item) =>
                                item.key === selectedList[0].key
                                  ? {
                                      ...item,
                                      quantity: Math.max(1, item.quantity - 1),
                                    }
                                  : item
                              )
                            )
                          }
                          className="px-3 py-2 font-semibold text-white transition-all duration-200 bg-red-500 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          value={selectedList[0].quantity}
                          readOnly
                          className="w-10 m-0 my-0 text-lg font-semibold text-center bg-white border border-gray-300 rounded-lg shadow-inner focus:ring-2 focus:ring-blue-500"
                          style={{ margin: "0px" }}
                        />
                        <button
                          onClick={() =>
                            setSelectedList((prev) =>
                              prev.map((item) =>
                                item.key === selectedList[0].key
                                  ? { ...item, quantity: item.quantity + 1 }
                                  : item
                              )
                            )
                          }
                          className="px-3 py-2 font-semibold text-white transition-all duration-200 bg-green-500 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  )}

                  {selectedList.length > 0 && auth.user.role !== "merchant" && (
                    <div className="flex justify-center mt-6">
                      <Button
                        ms={2}
                        p={2}
                        borderRadius="5px"
                        bg={"#FC6736"}
                        _hover={{ bg: "#16a34a " }}
                        // color={theme.light}
                        onClick={() => {
                          const checkIfItemExists = cart.items.find(
                            (item) =>
                              item.product?._id === product._id &&
                              item.property?._id === selectedList[0]?._id
                          );
                          if (checkIfItemExists)
                            return toast({
                              description: "قبل تم إضافة العنصر للسلة من",
                              status: "error",
                            });
                          dispatch(
                            addToCart({
                              product: {
                                _id: product._id,
                                name: product.name,
                                sale_price: product.sale_price,
                                properties: product.properties,
                              },
                              property: selectedList[0],
                              qty: selectedList[0]?.quantity ?? 1,
                            })
                          );
                          toast({
                            description: "تم اضافة المنتج للسلة بنجاح.",
                          });
                          setSelectedList([]);
                        }}
                        className="flex items-center justify-center gap-2 px-4 py-2 font-semibold text-white transition-colors duration-300 ease-in-out bg-[#FC6736] rounded-lg shadow-lg hover:bg-green-600"
                      >
                        <FiShoppingCart size={20} />
                        <span>أضف إلى السلة</span>
                      </Button>
                    </div>
                  )}

                  {(auth.user.role === "admin" ||
                    (auth.user.role === "merchant" &&
                      auth.user._id === product?.merchant?._id)) && (
                    <Flex justifyContent="center" my={5}>
                      <Box
                        className="p-4 text-lg text-center text-white bg-green-700 rounded-lg shadow-md"
                        width="fit-content"
                      >
                        {product?.percent > 0 ? (
                          <>
                            <Box>نسبة تسليم المنتج</Box>
                            {`${product.percent?.toFixed(2) ?? 0} %`}
                          </>
                        ) : (
                          "لم يتم تسليم اي طلبات حتى الأن"
                        )}
                      </Box>
                    </Flex>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full gap-4 py-4 bg-white rounded-lg shadow-md">
              <button
                className={`px-6 py-2 font-semibold text-white rounded-lg transition-all duration-300 ${
                  view === "description"
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "bg-gray-400 text-gray-800 hover:bg-gray-700"
                }`}
                onClick={() => setView("description")}
              >
                الوصف
              </button>
              <button
                className={`px-6 py-2 font-semibold text-white rounded-lg transition-all duration-300 ${
                  view === "properties"
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "bg-gray-400 text-gray-800 hover:bg-gray-700"
                }`}
                onClick={() => setView("properties")}
              >
                توزيع الاستوك
              </button>
            </div>

            <Box className="flex justify-center w-full py-4 my-4 bg-white rounded-lg shadow-md">
              {view === "description" && product?.description && (
                <div className="p-6 mt-4 bg-white border border-gray-200 rounded-lg shadow-md">
                  {product.description.split(/\n/).map((item, index) => (
                    <p
                      key={index}
                      className="mb-4 text-base leading-relaxed text-center text-gray-800"
                    >
                      {item.trim()}
                    </p>
                  ))}
                </div>
              )}

              {view === "properties" && (
                <div className="mb-4">
                  <div className="flex items-center mt-4">
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                      <thead className="bg-gray-100 border-b border-gray-200">
                        <tr className="text-sm font-semibold text-left text-gray-600">
                          <th className="px-4 py-3 text-center">#</th>
                          <th className="px-4 py-3 text-center">الخاصية</th>
                          <th className="px-4 py-3 text-center">الكمية</th>
                          {(auth.user.role === "admin" ||
                            (auth.user.role === "merchant" &&
                              auth.user._id === product?.merchant?._id)) && (
                            <>
                              <th className="px-4 py-3 text-center">معلق</th>
                              <th className="px-4 py-3 text-center">
                                جار التحضير
                              </th>
                              <th className="px-4 py-3 text-center">
                                تم الشحن
                              </th>
                              <th className="px-4 py-3 text-center">
                                تم التاجيل
                              </th>
                              <th className="px-4 py-3 text-center">
                                جار الاسترجاع
                              </th>
                              <th className="px-4 py-3 text-center">
                                طلب العميل الاسترجاع
                              </th>
                              <th className="px-4 py-3 text-center">
                                تم التسليم
                              </th>
                            </>
                          )}
                          {auth.user.role === "marketer" && (
                            <th className="px-4 py-3 text-center">
                              كود الخاصية
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="text-sm text-gray-700">
                        {product?.properties.map((prop, index) => (
                          <tr
                            key={index}
                            className="border-b border-gray-200 hover:bg-gray-50"
                          >
                            <td className="px-4 py-3 text-center">
                              {index + 1}
                            </td>
                            <td className="px-4 py-3 text-center">
                              {prop.key}
                            </td>
                            <td className="px-4 py-3 text-center">
                              {prop.value}
                            </td>
                            {(auth.user.role === "admin" ||
                              (auth.user.role === "merchant" &&
                                auth.user._id === product?.merchant?._id)) && (
                              <>
                                <td className="px-4 py-3 text-center">
                                  {prop.pendingOrders}
                                </td>
                                <td className="px-4 py-3 text-center">
                                  {prop.preparingOrders}
                                </td>
                                <td className="px-4 py-3 text-center">
                                  {prop.shippedOrders}
                                </td>
                                <td className="px-4 py-3 text-center">
                                  {prop.holdingOrders}
                                </td>
                                <td className="px-4 py-3 text-center">
                                  {prop.asToReturnOrders}
                                </td>
                                <td className="px-4 py-3 text-center">
                                  {prop.returned2Orders}
                                </td>
                                <td className="px-4 py-3 text-center">
                                  {prop.availableOrders}
                                </td>
                              </>
                            )}
                            {auth.user.role === "marketer" && (
                              <td className="px-4 py-3 text-center">
                                <CopyToClipboard
                                  text={prop._id}
                                  onCopy={() => {
                                    toast({
                                      position: "top",
                                      description: "تم نسخ كود الخاصية",
                                    });
                                  }}
                                >
                                  <button className="text-blue-500 transition-colors duration-200 hover:text-blue-600 focus:outline-none">
                                    اضغط للنسخ
                                  </button>
                                </CopyToClipboard>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetails;
