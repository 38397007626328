import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  moderator: null,
  errors: []
}

export const getModerators = createAsyncThunk(
  "moderators/getModerators",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_moderators?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const createModerator = createAsyncThunk(
  "moderators/createModerator",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        '/api/create_moderator',
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateModerator = createAsyncThunk(
  "moderators/updateModerator",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_moderator/${args._id}`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

const moderatorsSlice = createSlice({
  name: "moderators",
  initialState,
  extraReducers: {
    [getModerators.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getModerators.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getModerators.rejected]: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.errors = action.payload.errors;
    },

    // create moderator
    [createModerator.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [createModerator.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.itemsCount++;
      state.data?.data?.length < 10 && state.data.data.push(action.payload.data);
      state.errors = [];
    },
    [createModerator.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update moderator
    [updateModerator.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateModerator.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(el => el._id === action.payload.data._id);
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateModerator.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    }
  }
});

export default moderatorsSlice.reducer;