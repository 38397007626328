import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";

import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import { getOrders, createNote } from "../../../store/orders/ordersSlice";
import theme from "../../global/theme";

const OrderNotes = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orders);
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (data) {
      dispatch(
        getOrders({
          page: 1,
          query: "",
          filter: JSON.stringify({ _id: data._id }),
        })
      );
    }
  }, [dispatch, data]);
  const currentOrder = orders.data?.data && orders.data.data[0];

  const handleFormSubmit = (values) => {
    dispatch(
      createNote({
        id: currentOrder?._id,
        sender: auth?.user?._id,
        ...values,
      })
    )
      .unwrap()
      .then(() => {
        reset({ body: "" });
        dispatch(
          getOrders({
            page: 1,
            query: "",
            filter: JSON.stringify({ _id: currentOrder._id }),
          })
        );
      });
  };

  return (
    <Modal isOpen={Boolean(data)} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex
            justifyContent="space-between"
            className="text-center"
            alignItems="center"
          >
            <Box w={"100%"}>ملاحظات الطلب</Box>
            <ModalCloseButton />
          </Flex>
        </ModalHeader>

        <ModalBody className="scrollable-modal-body">
          {auth?.user.role === "admin" ||
          currentOrder?.marketer?._id === auth?.user?._id ||
          currentOrder?.moderator?._id === auth?.user?._id ? (
            <>
              <Flex justifyContent="space-between" mb={6}>
                <Box color={theme.blue}>
                  <button
                    onClick={() =>
                      navigate(`/orders/order_details/${currentOrder?._id}`)
                    }
                  >
                    {t("pages.orders.serial_number")}:{" "}
                    {currentOrder?.serial_number}
                  </button>
                </Box>
                <Box>
                  {t("pages.orders.client_name")}: {currentOrder?.client_name}
                </Box>
              </Flex>

              <Box>
                {currentOrder?.replies && currentOrder?.replies.length === 0 ? (
                  <Box
                    h="200px"
                    textAlign="center"
                    bg={theme.light}
                    boxShadow={theme.shadow}
                    mt={6}
                    d="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    لا يوجد تعليقات
                  </Box>
                ) : (
                  currentOrder?.replies.map((el, index) => (
                    <Box
                      p={4}
                      boxShadow={theme.shadow}
                      borderRadius={8}
                      mt={2}
                      key={index}
                      bg={el?.sender?.role === "admin" ? theme.dark : "#dfdee1"}
                    >
                      <Box
                        fontWeight="bold"
                        fontSize="20px"
                        color={
                          el?.sender?.role === "admin"
                            ? theme.light
                            : theme.dark
                        }
                      >
                        {el?.sender?.name}{" "}
                        {el?.sender?._id === auth?.user?._id && " - me"}
                      </Box>
                      <Box
                        mb={4}
                        fontSize="16px"
                        color={
                          el?.sender?.role === "admin"
                            ? theme.light
                            : theme.dark
                        }
                      >
                        {el.body}
                      </Box>
                      <Box
                        fontSize="10px"
                        color={
                          el?.sender?.role === "admin"
                            ? theme.gary1
                            : theme.dark
                        }
                      >
                        {el.created_at
                          ? new Date(el.created_at).toLocaleString()
                          : "-"}
                      </Box>
                    </Box>
                  ))
                )}
              </Box>

              {currentOrder && currentOrder?.status !== "closed" && (
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Flex mt={8} bg={theme.light} p={2} boxShadow={theme.shadow}>
                    <Input
                      {...register("body", {
                        required: true,
                      })}
                      placeholder="اكتب رسالتك"
                    />
                    <Button
                      disabled={orders.isLoading}
                      type="submit"
                      marginInlineStart={4}
                    >
                      ارسال <FiIcons.FiSend />
                    </Button>
                  </Flex>
                </form>
              )}
            </>
          ) : (
            <Box>not allow</Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            {t("general.close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderNotes;
