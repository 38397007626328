import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  Select,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { updateOrdersStatus } from "../../../../store/orders/ordersSlice";

import theme from "../../../global/theme";

const UpdateOrdersStatusModal = ({ data, onClose, reverse = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      orders: data,
      status: data[0].status,
      holding_to: null,
    },
  });

  const isItemsStatusDiff = () => {
    for (let i = 0; i < data.length; i++) {
      const compareItem = data.find((el) => el.status !== data[i].status);
      if (compareItem) return true;
    }
    return false;
  };

  watch(["status"]);

  const currentStatus = data[0].status;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} padding={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            values.reverse = reverse;
            dispatch(updateOrdersStatus(values))
              .unwrap()
              .then((_) => {
                document.querySelectorAll("button").forEach((el) => {
                  el.remove();
                });
                alert("تم تحديث حالة الطلبات بنجاح!");
                window.location.reload();
              })
              .catch((_) => {
                onClose();
              });
          })}
        >
          {reverse ? (
            <>
              <ModalHeader
                textAlign="center"
                color="orange"
                textTransform="uppercase"
                fontSize={22}
              >
                إرجاع حالات الطلبات
              </ModalHeader>
              <ModalBody>
                {isItemsStatusDiff() ? (
                  <Box color={theme.light} textAlign="center">
                    حالات الطلب غير موحده
                  </Box>
                ) : (
                  <>
                    <Select
                      bg={theme.bg}
                      color={theme.dark}
                      {...register("status", {
                        required: `${t("validation.required")}`,
                      })}
                    >
                      {auth.user.role === "admin" &&
                        (currentStatus === "preparing" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="preparing"
                            >
                              إرجاع قبل تحضير للشحن
                            </option>
                          </>
                        ) : currentStatus === "declined1" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="declined1"
                            >
                              إرجاع قبل ملغي
                            </option>
                          </>
                        ) : currentStatus === "returned1" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="returned1"
                            >
                              إرجاع قبل مرتجع
                            </option>
                          </>
                        ) : currentStatus === "declined2" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="declined2"
                            >
                              إرجاع قبل ملغي
                            </option>
                          </>
                        ) : currentStatus === "returned2" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="returned2"
                            >
                              إرجاع قبل مرتجع
                            </option>
                          </>
                        ) : currentStatus === "shipped" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="shipped"
                            >
                              إرجاع قبل في الشحن
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="skip"
                            ></option>
                          </>
                        ) : currentStatus === "skip" ? (
                          <>
                            <option style={{ color: theme.dark }} value="skip">
                              إرجاع جار الاسترجاع
                            </option>
                          </>
                        ) : currentStatus === "available" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="collected"
                            >
                              إرجاع قبل تم التسليم
                            </option>
                          </>
                        ) : currentStatus === "ask_to_return" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="ask_to_return"
                            >
                              إرجاع قبل طلب العميل الاسترجاع
                            </option>
                          </>
                        ) : (
                          <option style={{ color: theme.dark }} value="">
                            لا يوجد حالات اخرى
                          </option>
                        ))}
                    </Select>
                    {errors.status?.message && (
                      <Text color="red.600" marginTop={2}>
                        {errors.status.message}
                      </Text>
                    )}

                    {getValues("status") === "holding" && (
                      <>
                        <Input
                          mt={4}
                          color={theme.light}
                          colorScheme={theme.dark}
                          type="datetime-local"
                          {...register("holding_to", {
                            required: {
                              value: true,
                              message: `${t("validation.required")}`,
                            },
                          })}
                        />
                        {errors.holding_to?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.holding_to.message}
                          </Text>
                        )}
                      </>
                    )}
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                <Flex justifyContent="flex-end">
                  <Button
                    type="submit"
                    rightIcon={<FiIcons.FiSave />}
                    color="white"
                    bg="green"
                    paddingInline={4}
                    paddingBlock={2}
                    height="auto"
                    textTransform="capitalize"
                    isLoading={orders.isLoading}
                    _hover={{ background: "green" }}
                  >
                    {t("general.save")}
                  </Button>
                  <Button
                    type="button"
                    onClick={onClose}
                    rightIcon={<FiIcons.FiMinimize2 />}
                    color="white"
                    bg="red.600"
                    paddingInline={4}
                    paddingBlock={2}
                    height="auto"
                    textTransform="capitalize"
                    marginInlineStart={4}
                    isLoading={orders.isLoading}
                    _hover={{ background: "red.600" }}
                  >
                    {t("general.close")}
                  </Button>
                </Flex>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader
                textAlign="center"
                color="orange"
                textTransform="uppercase"
                fontSize={22}
              >
                تحديث حالات الطلبات
              </ModalHeader>
              <ModalBody>
                {isItemsStatusDiff() ? (
                  <Box color={theme.light} textAlign="center">
                    حالات الطلب غير موحده
                  </Box>
                ) : (
                  <>
                    <Select
                      bg={theme.bg}
                      color={theme.dark}
                      {...register("status", {
                        required: `${t("validation.required")}`,
                      })}
                    >
                      {auth.user.role === "admin" &&
                        (currentStatus === "pending" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="preparing"
                            >
                              تحضير للشحن
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="holding"
                            >
                              تاجيل الطلب
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="declined1"
                            >
                              ملغي
                            </option>
                          </>
                        ) : currentStatus === "preparing" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="shipped"
                            >
                              في الشحن
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="declined2"
                            >
                              ملغي
                            </option>
                          </>
                        ) : currentStatus === "holding" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="preparing"
                            >
                              تحضير للشحن
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="declined1"
                            >
                              ملغي
                            </option>
                          </>
                        ) : currentStatus === "shipped" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="available"
                            >
                              تم التسليم
                            </option>
                            <option style={{ color: theme.dark }} value="skip">
                              جار الاسترجاع
                            </option>
                          </>
                        ) : currentStatus === "skip" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="returned1"
                            >
                              مرتجع
                            </option>
                          </>
                        ) : currentStatus === "available" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="ask_to_return"
                            >
                              طلب العميل الاسترجاع
                            </option>
                          </>
                        ) : currentStatus === "ask_to_return" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="returned2"
                            >
                              مرتجع
                            </option>
                          </>
                        ) : (
                          <option style={{ color: theme.dark }} value="">
                            لا يوجد حالات اخرى
                          </option>
                        ))}
                    </Select>
                    {errors.status?.message && (
                      <Text color="red.600" marginTop={2}>
                        {errors.status.message}
                      </Text>
                    )}

                    {getValues("status") === "holding" && (
                      <>
                        <Input
                          mt={4}
                          color={theme.light}
                          colorScheme={theme.dark}
                          type="datetime-local"
                          {...register("holding_to", {
                            required: {
                              value: true,
                              message: `${t("validation.required")}`,
                            },
                          })}
                        />
                        {errors.holding_to?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.holding_to.message}
                          </Text>
                        )}
                      </>
                    )}
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                <Flex justifyContent="flex-end">
                  <Button
                    type="submit"
                    rightIcon={<FiIcons.FiSave />}
                    color="white"
                    bg="green"
                    paddingInline={4}
                    paddingBlock={2}
                    height="auto"
                    textTransform="capitalize"
                    isLoading={orders.isLoading}
                    _hover={{ background: "green" }}
                  >
                    {t("general.save")}
                  </Button>
                  <Button
                    type="button"
                    onClick={onClose}
                    rightIcon={<FiIcons.FiMinimize2 />}
                    color="white"
                    bg="red.600"
                    paddingInline={4}
                    paddingBlock={2}
                    height="auto"
                    textTransform="capitalize"
                    marginInlineStart={4}
                    isLoading={orders.isLoading}
                    _hover={{ background: "red.600" }}
                  >
                    {t("general.close")}
                  </Button>
                </Flex>
              </ModalFooter>
            </>
          )}
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateOrdersStatusModal;
