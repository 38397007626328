import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from 'js-cookie';

let initialState = {
  isLoading: false,
  data: {},
  user: null,
  errors: []
}

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_users?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        '/api/create_user',
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_user/${args._id}`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const openUser = createAsyncThunk(
  "users/openUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `/api/open_user/${args._id}`,
        {},
        {
          headers: {
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "users/updateUserPassword",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_user_password/${args.id}`,
        args.values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.errors = action.payload.errors;
    },

    // create user
    [createUser.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [createUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.itemsCount++;
      state.data?.data?.length < 10 && state.data.data.push(action.payload.data);
      state.errors = [];
    },
    [createUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update user
    [updateUser.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(el => el._id === action.payload.data._id);
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // open user
    // login
    [openUser.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [openUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
      Cookies.set("token", action.payload.token, {
        expires: 1 // 1 day
      });
    },
    [openUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update user password
    [updateUserPassword.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateUserPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
    },
    [updateUserPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },
  }
});

export default usersSlice.reducer;