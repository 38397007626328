import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  product: null,
  errors: [],
};

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_products/${
          thunkApi.getState().auth.user._id
        }?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const getProductById = createAsyncThunk(
  "products/getProductById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/products/${args.productId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_product", args, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);
export const addFavoriteProduct = createAsyncThunk(
  "products/addFavoriteProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/myFavorites", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const removeFavoriteProduct = createAsyncThunk(
  "products/removeFavoriteProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `/api/myFavorites/${args.productId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_product/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateAccessProduct = createAsyncThunk(
  "products/updateAccessProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_access_product/${args._id}`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getProducts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.errors = action.payload.errors;
    },

    [getProductById.pending]: (state) => {
      state.isLoading = true;
      state.product = null;
      state.errors = [];
    },
    [getProductById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.product = action.payload.product;
      state.errors = [];
    },
    [getProductById.rejected]: (state, action) => {
      state.isLoading = false;
      state.product = null;
      state.errors = action.payload.errors;
    },

    // create product
    [createProduct.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [createProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.itemsCount++;
      state.data?.data?.length < 10 &&
        state.data.data.push(action.payload.data);
      state.errors = [];
    },
    [createProduct.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update product
    [updateProduct.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(
        (el) => el._id === action.payload.data._id
      );
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateProduct.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update access product
    [updateAccessProduct.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateAccessProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(
        (el) => el._id === action.payload.data._id
      );
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateAccessProduct.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    //  add to favorites product
    [addFavoriteProduct.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [addFavoriteProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.data = state.data.data.map((el) =>
        el._id === action.payload.data?.product
          ? { ...el, isFavorite: true }
          : el
      );
      state.errors = [];
    },
    [addFavoriteProduct.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },
    //  remove to favorites product
    [removeFavoriteProduct.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [removeFavoriteProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.data = state.data.data.map((el) =>
        el._id === action.payload.data ? { ...el, isFavorite: false } : el
      );
      state.errors = [];
    },
    [removeFavoriteProduct.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },
  },
});

export default productsSlice.reducer;
