import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth/authSlice";
import sidebarSlice from "./sidebar/sidebarSlice";

import cartSlice from "./cart/cartSlice";

import notificationsSlice from "./notifications/notificationsSlice";
// dashboard slices
import statisticsSlice from "./dashboard/statisticsSlice";

import rulesSlice from "./rules/rulesSlice";
import categoriesSlice from "./categories/categoriesSlice";
import shippingCompaniesSlice from "./shippingCompanies/shippingCompaniesSlice";
import priceListSlice from "./priceList/priceListSlice";
import imageSlice from "./images/imageSlice";

import usersSlice from "./users/usersSlice";
import moderatorsSlice from "./moderators/moderatorsSlice";
import warehousesSlice from "./warehouses/warehousesSlice";
import productsSlice from "./products/productsSlice";
import ordersSlice from "./orders/ordersSlice";
import historySlice from "./history/historySlice";
import accountsSlice from "./accounts/accountsSlice";
import requestsSlice from "./requests/requestsSlice";

import supportsSlice from "./supports/supportsSlice";

export default configureStore({
  reducer: {
    cart: cartSlice,
    sidebar: sidebarSlice,
    notifications: notificationsSlice,
    statistics: statisticsSlice,
    rules: rulesSlice,
    categories: categoriesSlice,
    shippingCompanies: shippingCompaniesSlice,
    priceList: priceListSlice,
    auth: authSlice,
    images: imageSlice,
    users: usersSlice,
    moderators: moderatorsSlice,
    warehouses: warehousesSlice,
    products: productsSlice,
    orders: ordersSlice,
    history: historySlice,
    accounts: accountsSlice,
    requests: requestsSlice,
    supports: supportsSlice,
  },
});
