import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = "{}", } = args;
      const { data } = await axios.get(
        `/api/get_orders?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        '/api/create_order',
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const createOrders = createAsyncThunk(
  "orders/createOrders",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        '/api/create_orders',
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateOrder = createAsyncThunk(
  "orders/createOrder",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_order/${args._id}`,
        args.values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateOrderCallAction = createAsyncThunk(
  "orders/createOrder",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_order_call_action/${args._id}`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateOrdersStatus = createAsyncThunk(
  "orders/updateOrdersStatus",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        '/api/update_orders_status',
        args,
        {
          headers: {
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateOrderShippingCompany = createAsyncThunk(
  "orders/updateOrderShippingCompany",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_order_shipping_company`,
        args,
        {
          headers: {
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const createNote = createAsyncThunk(
  "orders/createNote",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `/api/create_order_note/${args.id}`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  extraReducers: {
    [getOrders.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.errors = action.payload.errors;
    },

    // create order
    [createOrder.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [createOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
    },
    [createOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // create order
    [createOrders.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [createOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
    },
    [createOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update order status
    [updateOrder.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(el => el._id === action.payload.data._id);
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update order status
    [updateOrdersStatus.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateOrdersStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
    },
    [updateOrdersStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update order status
    [updateOrderCallAction.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateOrderCallAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
    },
    [updateOrderCallAction.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update order shipping company
    [updateOrderShippingCompany.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateOrderShippingCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errors = [];
    },
    [updateOrderShippingCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // create note
    [createNote.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [createNote.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(el => el._id === action.payload.data._id);
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [createNote.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },
  }
});

export default ordersSlice.reducer;