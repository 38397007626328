import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

import Table from '../../shared/table/Table';

import theme from '../../global/theme';

const MyProductsTable = ({ data, page }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('pages.products.name')}</th>
          <th>{t('pages.products.barcode')}</th>
          <th>{t('pages.products.purchase_price')}</th>
          <th>{t('general.action')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{((page - 1) * 10) + (index + 1)}</td>
            <td>{el.name}</td>
            <td>{el.barcode}</td>
            <td>{el.purchase_price}</td>
            <td>
              <ChakraLink as={Link} to={`/available_products/${el._id}`} bg="#333054"
                d="inline-block" paddingBlock={2} paddingInline={4} borderRadius="5px" textAlign="center" color={theme.light}
                mt={6} textTransform="capitalize">
                {t('general.details')}
              </ChakraLink>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MyProductsTable