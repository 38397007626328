import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  Box,
  Text,
  Divider,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import {
  MdFavoriteBorder,
  MdAddShoppingCart,
  MdHome,
  MdGridView,
  MdSearch,
} from "react-icons/md";
import { toggleSidebar } from "../../../store/sidebar/sidebarSlice";
import { logout } from "../../../store/auth/authSlice";
import NotificationsDrawer from "../../modules/notifications/NotificationsDrawer";
import CartDrawer from "../../modules/cart/CartDrawer";
import theme from "../../global/theme";

const BottomBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const notifications = useSelector((state) => state.notifications);
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCartOpen,
    onOpen: onCartOpen,
    onClose: onCartClose,
  } = useDisclosure();

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate(`/available_products?query=${searchQuery}&page=1`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      {/* Bottom bar for small screens */}
      <Flex
        position="fixed"
        borderRadius="7px 7px 0 0"
        bottom={0}
        left={0}
        right={0}
        justifyContent="space-between"
        alignItems="center"
        bg={theme.dark}
        px={4}
        py={2}
        boxShadow="0 -2px 5px rgba(0, 0, 0, 0.1)"
        display={{ base: "flex", md: "none" }} // Show only on small screens
        zIndex={999}
      >
        {/* Main Page */}
        <IconButton
          aria-label="Home"
          icon={<MdHome size="24px" />}
          bg="transparent"
          color="white"
          onClick={() => navigate("/")}
          _hover={{ color: theme.primary }}
          _focus={{ outline: "none" }}
        />
        {/* Cart */}
        <IconButton
          aria-label="Cart"
          icon={
            <Box position="relative">
              <FiIcons.FiShoppingCart size="24px" />
              <Box
                position="absolute"
                top="-8px"
                right="-8px"
                w="16px"
                h="16px"
                bg="red.500"
                borderRadius="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="xs"
                color="white"
              >
                {cart.items.length}
              </Box>
            </Box>
          }
          bg="transparent"
          color="white"
          onClick={onCartOpen}
          _hover={{ color: theme.primary }}
          _focus={{ outline: "none" }}
        />
        {/* Add Order */}
        <IconButton
          aria-label="Add Order"
          icon={<MdAddShoppingCart size="24px" />}
          bg="transparent"
          color="white"
          onClick={() => navigate("/orders?open_modal=true")}
          _hover={{ color: theme.primary }}
          _focus={{ outline: "none" }}
        />
        {/* Favorites */}
        <IconButton
          aria-label="Favorites"
          icon={<MdFavoriteBorder size="24px" />}
          bg="transparent"
          color="white"
          onClick={() => navigate("/favorite_products")}
          _hover={{ color: theme.primary }}
          _focus={{ outline: "none" }}
        />
        {/* Available Products */}
        <IconButton
          aria-label="Available Products"
          icon={<MdGridView size="24px" />} // Grid view icon for available products
          bg="transparent"
          color="white"
          onClick={() => navigate("/available_products")}
          _hover={{ color: theme.primary }}
          _focus={{ outline: "none" }}
        />
      </Flex>

      {/* Drawers */}
      <NotificationsDrawer isOpen={isOpen} onClose={onClose} />
      <CartDrawer isOpen={isCartOpen} onClose={onCartClose} />
    </>
  );
};

export default BottomBar;
