import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  SimpleGrid,
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Image,
  Td,
} from "@chakra-ui/react";

import Filter from "./actions/Filter";

import * as FiIcons from "react-icons/fi";
import { GiShoppingCart } from "react-icons/gi";
import { TbShoppingBagCheck } from "react-icons/tb";
import { GiTakeMyMoney } from "react-icons/gi";
import { BiSolidShoppingBags } from "react-icons/bi";
import { LiaShoppingCartSolid } from "react-icons/lia";
import { GiMoneyStack } from "react-icons/gi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { LiaPercentSolid } from "react-icons/lia";
import { getStatistics } from "../../../store/dashboard/statisticsSlice";
import { getImages } from "../../../store/images/imageSlice";
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

// import "./styles.css";

// // import required modules
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
import theme from "../../global/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { DashboardWrapper } from "./DashboardStyle";
import systemBg from "../../../assets/images/logo.png";
import banner from "../../../assets/images/Designer (4).jpeg";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statistics = useSelector((state) => state.statistics);
  const auth = useSelector((state) => state.auth);
  const { data: images } = useSelector((state) => state.images);
  const [filter, setFilter] = useState({});
  useEffect(() => {
    dispatch(getImages());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getStatistics({ filter: JSON.stringify(filter) }));
  }, [dispatch, filter]);

  return (
    <DashboardWrapper>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {/* Replace these SwiperSlide components with dynamic image loading */}
        {/* <SwiperSlide>
          {images.length > 0 &&
            images.map((image, index) => (
              <Image
                key={index}
                src={`/settings/${image.image}`}
                alt={`Slide ${index + 1}`}
                height="400px"
                width="50%"
                objectFit="cover"
              />
            ))}
        </SwiperSlide> */}
        {/* Add more SwiperSlide components as needed */}
      </Swiper>
      <Flex paddingBlock={8} justifyContent="space-between" alignItems="center">
        <Text
          fontWeight="bold"
          textTransform="capitalize"
          fontSize={{ base: 12, xl: 15 }}
          color={theme.dark}
        >
          التقارير العامة
        </Text>
        <Filter
          isLoading={statistics.isLoading}
          handleFilter={(values) => setFilter(values)}
          handleClearFilter={() => setFilter({})}
        />
      </Flex>

      {((auth.user.role === "admin" &&
        auth.user?.rule?.permissions.includes("manage_dashboard")) ||
        auth.user.role === "moderator" ||
        auth.user.role === "marketer" ||
        auth.user.role === "merchant") && (
        <SimpleGrid
          columns={{ base: 2, lg: 3, xl: 5 }}
          spacing={{ base: 4, xl: 6 }}
        >
          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F78B00">
                <GiShoppingCart size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.ordersOfToday}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                طلبات اليوم
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F44336">
                <LiaShoppingCartSolid size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.ordersOfWeek}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                طلبات هذا الاسبوع
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F44336">
                <BiSolidShoppingBags size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.ordersOfMonth}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                طلبات هذا الشهر
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F44336">
                <TbShoppingBagCheck size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.totalOrders}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                اجمالي الطلبات
              </Text>
            </Box>
          </Flex>

          {auth.user.role !== "moderator" && (
            <Flex
              boxShadow={theme.shadow}
              padding={{ base: 3, xl: 6 }}
              alignItems="center"
              justifyContent="space-between"
              className="text-[#0C2D57] bg-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
            >
              <Flex justifyContent="space-between">
                <Box color={theme.success}>
                  <GiTakeMyMoney size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                  {(statistics.data?.totalDoneRequests &&
                    statistics.data?.totalDoneRequests[0]?.amount) ??
                    0}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 12, xl: 15 }}
                  textTransform="capitalize"
                >
                  ارباح تم سحبها
                </Text>
              </Box>
            </Flex>
          )}

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="text-[#0C2D57] bg-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color={theme.success}>
                <GiMoneyStack size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {(statistics.data?.totalSales &&
                  statistics.data?.totalSales[0]?.total) ??
                  0}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                اجمالي المبيعات
              </Text>
            </Box>
          </Flex>

          {auth.user.role !== "moderator" && (
            <Flex
              boxShadow={theme.shadow}
              padding={{ base: 3, xl: 6 }}
              alignItems="center"
              justifyContent="space-between"
              className="text-[#0C2D57] bg-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
            >
              <Flex justifyContent="space-between">
                <Box color={theme.success}>
                  <FaMoneyBillTrendUp size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                  {(statistics.data?.totalIncome &&
                    statistics.data?.totalIncome[0]?.commission) ??
                    0}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 12, xl: 15 }}
                  textTransform="capitalize"
                >
                  اجمالي الارباح
                </Text>
              </Box>
            </Flex>
          )}

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="text-[#FFB0B0] bg-[#FC6736] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color={theme.light}>
                <FiIcons.FiPercent size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                <span style={{ color: theme.light }}>
                  {statistics.data?.percent?.toFixed(2) ?? "-"}
                </span>
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                نسبة التسليم
              </Text>
            </Box>
          </Flex>

          {(auth.user.role === "admin" || auth.user.role === "marketer") && (
            <Flex
              boxShadow={theme.shadow}
              padding={{ base: 3, xl: 6 }}
              alignItems="center"
              justifyContent="space-between"
              className="text-[#FFB0B0] bg-[#FC6736]  rounded-xl shadow-xl hover:shadow-md transition-shadow"
            >
              <Flex justifyContent="space-between">
                <Box color={theme.light}>
                  <LiaPercentSolid size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                  <span style={{ color: theme.light }}>
                    {statistics.data?.callCenterQuality?.toFixed(2) ?? "-"}
                  </span>
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 12, xl: 15 }}
                  textTransform="capitalize"
                >
                  جودة العملاء ونسبة التأكيد
                </Text>
              </Box>
            </Flex>
          )}
        </SimpleGrid>
      )}

      {auth.user?.role === "admin" &&
        auth.user?.rule?.permissions.includes("manage_dashboard") && (
          <Box>
            <Flex
              paddingBlock={8}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                fontWeight="bold"
                textTransform="capitalize"
                fontSize={{ base: 12, xl: 15 }}
                color={theme.dark}
              >
                {t("general.details")}
              </Text>
            </Flex>

            <SimpleGrid
              columns={{ base: 2, lg: 3, xl: 5 }}
              spacing={{ base: 4, xl: 6 }}
            >
              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.totalProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.activeProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات النشطه
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.disActiveProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات الغير نشطة
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.users}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المستخدمين
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.merchants}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    التجار
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.marketers}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المسوقين
                  </Text>
                </Box>
              </Flex>
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, lg: 2, xl: 3 }} spacing={6} mt={8}>
              <Box>
                <Text
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={{ base: 12, xl: 15 }}
                  color={theme.dark}
                  mb={4}
                >
                  المسوقين الآكثر نشاطا
                </Text>
                <Table bg="white">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>اسم المسوق</Th>
                      <Th>عدد الاوردرات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {statistics.data?.mostMarketers?.map((el, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{el.user?.name}</Td>
                        <Td>{el.orderCount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <Box>
                <Text
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={{ base: 12, xl: 15 }}
                  color={theme.dark}
                  mb={4}
                >
                  المسوقين الاكثر نشاطا هذا الاسبوع
                </Text>
                <Table bg="white">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>اسم المسوق</Th>
                      <Th>عدد الاوردرات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {statistics.data?.marketersOfWeek?.map((el, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{el.user?.name}</Td>
                        <Td>{el.orderCount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <Box>
                <Text
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={{ base: 12, xl: 15 }}
                  color={theme.dark}
                  mb={4}
                >
                  المسوقين الاكثر نشاطا هذا الشهر
                </Text>
                <Table bg="white">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>اسم المسوق</Th>
                      <Th>عدد الاوردرات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {statistics.data?.marketersOfMonth?.map((el, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{el.user?.name}</Td>
                        <Td>{el.orderCount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </SimpleGrid>
          </Box>
        )}
    </DashboardWrapper>
  );
};

export default Dashboard;
