import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

export const getRequests = createAsyncThunk(
  "requests/getRequests",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_requests?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const createRequest = createAsyncThunk(
  "requests/createRequest",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        '/api/create_request',
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateRequest = createAsyncThunk(
  "requests/updateRequest",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_request/${args.id}`,
        args.values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const deleteRequest = createAsyncThunk(
  "requests/deleteRequest",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `/api/delete_request/${args._id}`,
        {
          headers: {
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
)

const requestsSlice = createSlice({
  name: "requests",
  initialState,
  extraReducers: {
    [getRequests.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getRequests.rejected]: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.errors = action.payload.errors;
    },

    // create request
    [createRequest.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [createRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.itemsCount++;
      state.data?.data?.length < 10 && state.data.data.push(action.payload.data);
      state.errors = [];
    },
    [createRequest.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update request
    [updateRequest.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(el => el._id === action.payload.data._id);
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateRequest.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // delete request
    [deleteRequest.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [deleteRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.data = state.data.data.filter(el => el._id !== action.payload.data._id);
      state.errors = [];
    },
    [deleteRequest.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    }
  }
});

export default requestsSlice.reducer;