import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@chakra-ui/react";

import Sidebar from "../../components/layout/sidebar/Sidebar";
import Navbar from "../../components/layout/navbar/Navbar";
import Footer from "../../components/layout/footer/Footer";

import { checkAuthentication } from "../../store/auth/authSlice";

import { AppWrapper } from "../../AppStyle";
import { toggleSidebar } from "../../store/sidebar/sidebarSlice";

const ProtectedRoutes = () => {
  const auth = useSelector((state) => state.auth);
  const sidebar = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!sidebar.isNotOpened && window.location.pathname.includes("/orders")) {
      dispatch(toggleSidebar());
    }
  }, []);
  useEffect(() => {
    dispatch(checkAuthentication())
      .unwrap()
      .then((_) => {
        setLoaded((prev) => !prev);
      })
      .catch((_) => {
        setLoaded((prev) => !prev);
      });
  }, [dispatch]);

  return loaded ? (
    auth.isAuth ? (
      <AppWrapper>
        <Sidebar />
        <Box
          className={`content-wrapper ${sidebar.isNotOpened ? "active" : ""}`}
        >
          <Box className="content">
            <Navbar />
            <Box className="app-content">
              <Outlet />
            </Box>
          </Box>
          <Footer />
        </Box>
      </AppWrapper>
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <h1>verify user ...</h1>
  );
};

export default ProtectedRoutes;
