import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  errors: [],
}

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_categories?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const createCategory = createAsyncThunk(
  "categories/createCategory",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        '/api/create_category',
        args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_category/${args.id}`,
        args.values,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `/api/delete_category/${args._id}`,
        {
          headers: {
            'Authorization': thunkApi.getState().auth.token
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ?
          { errors: [{ 'msg': 'something went wrong' }] }
          :
          errors.response.data
      );
    }
  }
)

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: {
    [getCategories.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getCategories.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getCategories.rejected]: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.errors = action.payload.errors;
    },

    // create category
    [createCategory.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [createCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.itemsCount++;
      state.data?.data?.length < 10 && state.data.data.push(action.payload.data);
      state.errors = [];
    },
    [createCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // update category
    [updateCategory.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      const indexAt = state.data.data?.findIndex(el => el._id === action.payload.data._id);
      state.data.data[indexAt] = action.payload.data;
      state.errors = [];
    },
    [updateCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // delete category
    [deleteCategory.pending]: state => {
      state.isLoading = true;
      state.errors = [];
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.data = state.data.data.filter(el => el._id !== action.payload.data._id);
      state.errors = [];
    },
    [deleteCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    }
  }
});

export default categoriesSlice.reducer;