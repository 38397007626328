import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Button, Text, SimpleGrid, Alert, AlertIcon } from '@chakra-ui/react';

import Pagination from '../../shared/pagination/Pagination';
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import ProductsTable from './ProductsTable';
import CreateModal from './actions/CreateModal';
import UpdateModal from './actions/UpdateModal';
import UpdateAccessModal from './actions/UpdateAccessModal';
import Filter from './actions/Filter';

import { getProducts } from '../../../store/products/productsSlice';

import { ProductsWrapper } from './ProductsStyle';

import theme from '../../global/theme';
import InputSearch from "../../shared/inputSearch/InputSearch";

const Products = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector(state => state.products);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showUpdateProduct, setShowUpdateProduct] = useState(false);
  const [showUpdateAccess, setShowUpdateAccess] = useState(false);

  useEffect(() => {
    dispatch(getProducts({ page, query, filter }));
  }, [dispatch, page, query, filter]);


  return (
    <ProductsWrapper>
      <Breadcrumbs currentPage={t('pages.products.products')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />

      {products.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {products.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button bg="red.600" textTransform="uppercase"
            fontWeight="bold" fontSize={18} marginInlineEnd={4} color="white" _hover={{ bg: 'red.600' }}
            onClick={() => setShowCreateProduct(true)}
          >
            {t('general.create')}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={products.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={products.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {products.data?.itemsCount > 0 ? (
        <ProductsTable
          data={products.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateProduct(el)}
          handleUpdateAccess={(el) => setShowUpdateAccess(el)}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.products.no_products')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={products.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateProduct && <CreateModal onClose={() => setShowCreateProduct(false)} />}
      {showUpdateProduct && <UpdateModal data={showUpdateProduct} onClose={() => setShowUpdateProduct(false)} />}
      {showUpdateAccess && <UpdateAccessModal data={showUpdateAccess} onClose={() => setShowUpdateAccess(false)} />}
    </ProductsWrapper>
  )
}

export default Products