import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
// import Cookies from 'js-cookie';
// import axios from 'axios';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input, Box, Textarea,
  SimpleGrid, Flex, Text, Alert, AlertIcon,
  UnorderedList, ListItem, Select,
  useToast
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import Search from '../../../shared/search/Search';

import { getPriceList } from '../../../../store/priceList/priceListSlice';
import { updateOrder } from '../../../../store/orders/ordersSlice';
import { getProducts } from '../../../../store/products/productsSlice';

import { cities } from '../../../../utilities/places';
import { convertArNumbersToEnNumbers } from '../../../../utilities/order';

import theme from '../../../global/theme';

const UpdateModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const orders = useSelector(state => state.orders);
  const priceList = useSelector(state => state.priceList);
  const products = useSelector(state => state.products);
  const auth = useSelector(state => state.auth);

  const toast = useToast({ duration: 2000, position: 'top', status: 'error' });

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: data
  });

  const { fields: items, append: appendItem, remove: removeItem } = useFieldArray({
    name: "items",
    control
  });

  watch(['items', 'shipping_governorate', 'total']);

  const [productsPage, setProductsPage] = useState(1);
  const [productsQuery, setProductsQuery] = useState("");
  const productsDefaultFilter = { is_active: true };
  const [productsFilter] = useState(JSON.stringify(productsDefaultFilter));
  useEffect(() => {
    dispatch(getProducts({ page: productsPage, query: productsQuery, filter: productsFilter }));
  }, [dispatch, productsPage, productsQuery, productsFilter]);

  const [priceListPage, setPriceListPage] = useState(1);
  const [priceListQuery, setPriceListQuery] = useState("");
  const [priceListFilter] = useState(JSON.stringify({ is_active: true }));
  useEffect(() => {
    dispatch(getPriceList({ page: priceListPage, size: 50, query: priceListQuery, filter: priceListFilter }));
  }, [dispatch, priceListPage, priceListQuery, priceListFilter]);

  const getTotalProductsPrice = () => {
    let total = 0;
    for (let i = 0; i < getValues('items').length; i++) {
      total += (getValues('items')[i].product?.sale_price || 0) * (+getValues('items')[i].qty || 0);
    }
    return total;
  }
  const getTotalItems = () => {
    let totalItems = 0;
    for (let i = 0; i < getValues('items').length; i++) {
      totalItems += (+getValues('items')[i].qty || 0);
    }
    return totalItems;
  }
  const getCommission = () => {
    const productsPrice = getTotalProductsPrice();
    const shipping = +getValues("shipping_governorate")?.price || 0;
    const total = +getValues("total") || 0;
    const commssion = total - (productsPrice + shipping);
    return commssion;
  }


  return (
    <Modal isOpen={true} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(async values => {
          const items = values.items;

          // global validation
          if (items.length === 0) return toast({ description: 'من فضلك اختر على الاقل منتج واحد!' });
          if (!values.shipping_governorate) return toast({ description: 'من فضلك اختر محافظة الشحن' });
          if (isNaN(getCommission()) || getCommission() < 0) return toast({ description: 'ادخل الاجمالي بشكل صحيح' });


          values['shipping_governorate'] = values.shipping_governorate?._id;
          values["commission"] = getCommission();
          values["client_phone1"] = convertArNumbersToEnNumbers(values["client_phone1"]);
          values["client_phone2"] = convertArNumbersToEnNumbers(values["client_phone2"]);

          for (let i = 0; i < items.length; i++) {
            items[i]['product'] = items[i]['product']?._id;
          }

          dispatch(updateOrder({ _id: values._id, values })).unwrap().then(_ => {
            document.querySelectorAll("button").forEach(el => {
              el.remove();
            });
            alert("تم تحديث الطلب بنجاح");
            window.location.reload();
          });
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.update')}
          </ModalHeader>
          <ModalBody>
            {orders.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
              <AlertIcon />
              {orders.errors?.map((error, index) => (
                <Text key={index}>{error?.msg}</Text>
              ))}
            </Alert>}

            <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing={6} marginBlock={8}>
              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  اسم العميل بالكامل
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.orders.client_name')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("client_name", {
                    required: `${t('validation.required')}`,
                    maxLength: {
                      value: 40,
                      message: `${t('validation.max_length')} 40`
                    }
                  })}
                />
                {errors.client_name?.message &&
                  <Text color="red.600" marginTop={2}>{errors.client_name.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.orders.client_phone1')}
                </FormLabel>
                <Input type="tel" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.orders.client_phone1')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("client_phone1", {
                    required: `${t('validation.required')}`,
                    minLength: {
                      value: 11,
                      message: `${t('validation.min_length')} 11`
                    },
                    maxLength: {
                      value: 11,
                      message: `${t('validation.max_length')} 11`
                    }
                  })}
                />
                {errors.client_phone1?.message &&
                  <Text color="red.600" marginTop={2}>{errors.client_phone1.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.orders.client_phone2')} - اختياري
                </FormLabel>
                <Input type="tel" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.orders.client_phone2')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("client_phone2", {
                    minLength: {
                      value: 11,
                      message: `${t('validation.min_length')} 11`
                    },
                    maxLength: {
                      value: 11,
                      message: `${t('validation.max_length')} 11`
                    }
                  })}
                />
                {errors.client_phone2?.message &&
                  <Text color="red.600" marginTop={2}>{errors.client_phone2.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  عنوان العميل تفصيلي
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.orders.client_address')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("client_address", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.client_address?.message &&
                  <Text color="red.600" marginTop={2}>{errors.client_address.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color={theme.light}>
                  {t('pages.orders.shipping_governorate')}
                </FormLabel>
                <Search
                  allowSearch={false}
                  allowPagination={false}
                  page={priceListPage}
                  itemsCount={priceList.data?.itemsCount}
                  data={priceList.data?.data}
                  type="fromGovernorate"
                  prop="governorate"
                  currentElement={getValues("shipping_governorate")}
                  handleChangePage={(page) => { setPriceListPage(page) }}
                  handleChangeQuery={(query) => { setPriceListQuery(query) }}
                  handleSelected={(item) => {
                    setValue("shipping_governorate", item);
                    setValue("city", "");
                  }}
                />
              </FormControl>

              {getValues("shipping_governorate") && (
                <FormControl>
                  <FormLabel fontWeight="bold" textTransform="capitalize" color={theme.light}>
                    {t('pages.orders.city')}
                  </FormLabel>
                  <Select bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                    placeholder={t('pages.orders.city')} _placeholder={{ textTransform: 'capitalize' }}
                    {...register("city", {
                      required: `${t('validation.required')}`
                    })}
                  >
                    {cities.filter(f => +f.governorate_id === getValues("shipping_governorate")?.governorate).map(city => (
                      <option style={{ color: '#222222' }} key={city.id} value={city.id}>{city.city_name_ar}</option>
                    ))}
                  </Select>
                  {errors.city?.message &&
                    <Text color="red.600" marginTop={2}>{errors.city.message}</Text>}
                </FormControl>
              )}

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.orders.page_name')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.orders.page_name')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("page_name")}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.orders.total')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.orders.total')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("total", {
                    required: `${t('validation.required')}`,
                    min: 1
                  })}
                />
                {errors.total?.message &&
                  <Text color="red.600" marginTop={2}>{errors.total.message}</Text>}
              </FormControl>
            </SimpleGrid>

            {items.map((item, index) => (
              <SimpleGrid
                key={item.id} columns={{ sm: 1, md: 2, lg: 5 }}
                border={`1px solid ${theme.border}`} alignItems="center"
                paddingBlock={2} mb={2} borderRadius={4}
              >
                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>المنتج</Box>
                  <Search
                    page={productsPage}
                    itemsCount={products.data?.itemsCount}
                    data={products.data?.data}
                    prop="name"
                    currentElement={getValues(`items.${index}.product`)}
                    handleChangePage={(page) => { setProductsPage(page) }}
                    handleChangeQuery={(query) => { setProductsQuery(query) }}
                    handleSelected={(item) => {
                      // check if the product selected
                      const products = getValues('items');
                      for (let i = 0; i < products.length; i++) {
                        if (products[i].product?.warehouse?._id && products[i].product?.warehouse?._id !== item.warehouse?._id) {
                          return alert('choose another warehouse');
                        }
                      }
                      setValue(`items.${index}.product`, item);
                    }}
                  />
                </Box>
                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>الخاصية</Box>
                  <Select minW="100px" color={theme.light}
                    placeholder={t('general.select')} _placeholder={{ color: theme.dark }}
                    {...register(`items.${index}.property`, {
                      required: `${t('general.required')}`,
                    })}
                  >
                    {getValues(`items.${index}.product.properties`)?.map((prop, idx) => (
                      <option
                        key={idx} style={{ color: theme.dark }}
                        value={prop._id}
                      >
                        {prop.key} - {prop.value} قطع
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>الكمية</Box>
                  <Input
                    bg="#D2DFEA" type="number" fontSize="1.4rem" minW="70px" placeholder="eg: 3"
                    {...register(`items.${index}.qty`, {
                      required: `${t('general.required')}`,
                      // min: 1,
                      // max: 6
                    })}
                  />
                </Box>
                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>سعر القطعة الواحده</Box>
                  <Box color={theme.light}>{getValues(`items.${index}.product.sale_price`)} جم</Box>
                </Box>
                <Box marginInline={5} mt={2}>
                  {index === 0 ? (
                    <Button
                      bg={theme.success} color={theme.light} size="xs"
                      onClick={() => {
                        appendItem({ qty: 1 })
                      }}
                    >
                      إضافة قطعة اخرى للطلب
                    </Button>
                  ) : (
                    <Button
                      bg="red" color="white" size="xs"
                      onClick={() => removeItem(index)}
                    >
                      حذف قطعة
                    </Button>
                  )}
                </Box>
              </SimpleGrid>
            ))}

            <FormControl mt={6}>
              <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                {t('pages.orders.note')}
              </FormLabel>
              <Textarea bg={theme.bg} color={theme.dark} border="none"
                placeholder={t('pages.orders.note')} _placeholder={{ textTransform: 'capitalize' }}
                {...register("note", {
                  maxLength: {
                    value: 150,
                    message: `${t('validation.max_length')} 150`
                  }
                })}
              />
              {errors.note?.message &&
                <Text color="red.600" marginTop={2}>{errors.note.message}</Text>}
            </FormControl>

            <Box color="white" mt={6} d="flex" justifyContent="flex-end">
              <Box d="flex">
                <UnorderedList m="0" listStyleType="none" textAlign="end" textTransform="capitalize">
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {getTotalItems() || '-'}
                  </ListItem>
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {getTotalProductsPrice() || '-'}
                  </ListItem>
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {getValues("shipping_governorate")?.price ?? '-'}
                  </ListItem>
                  {auth.user.role !== "moderator" && (
                    <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                      {getCommission()}
                    </ListItem>
                  )}
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {getValues("total") || '-'}
                  </ListItem>
                </UnorderedList>
                <UnorderedList m="0" listStyleType="none" textAlign="end" textTransform="capitalize">
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {t('pages.orders.quantity')}
                  </ListItem>
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {t('pages.orders.products_price')}
                  </ListItem>
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {t('pages.orders.shipping')}
                  </ListItem>
                  {auth.user.role !== "moderator" && (
                    <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                      {t('pages.orders.commission')}
                    </ListItem>
                  )}
                  <ListItem border={`1px solid ${theme.light}`} paddingBlock={2} paddingInline={6}>
                    {t('pages.orders.total')}
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={orders.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={orders.isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default UpdateModal