import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: [],
  errors: [],
};

// Fetch images
export const getImages = createAsyncThunk(
  "images/getImages",
  async (_, thunkApi) => {
    try {
      const response = await axios.get("/api/images", {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(response.data.data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.data?.errors || [{ msg: "Something went wrong" }]
      );
    }
  }
);

// Create an image
export const createImage = createAsyncThunk(
  "images/createImage",
  async (formData, thunkApi) => {
    try {
      const response = await axios.post("/api/images", formData, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
          "Content-Type": "multipart/form-data",
        },
      });
      return thunkApi.fulfillWithValue(response.data.data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.data?.errors || [{ msg: "Something went wrong" }]
      );
    }
  }
);

// Update an image
export const updateImage = createAsyncThunk(
  "images/updateImage",
  async ({ id, formData }, thunkApi) => {
    try {
      const response = await axios.patch(`/api/images/${id}`, formData, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
          "Content-Type": "multipart/form-data",
        },
      });
      return thunkApi.fulfillWithValue(response.data.data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.data?.errors || [{ msg: "Something went wrong" }]
      );
    }
  }
);

// Delete an image
export const deleteImage = createAsyncThunk(
  "images/deleteImage",
  async (id, thunkApi) => {
    try {
      const response = await axios.delete(`/api/images/${id}`, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(response.data.data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.data?.errors || [{ msg: "Something went wrong" }]
      );
    }
  }
);

const imagesSlice = createSlice({
  name: "images",
  initialState,
  extraReducers: {
    // Fetch images
    [getImages.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [getImages.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.errors = [];
    },
    [getImages.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // Create image
    [createImage.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [createImage.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.push(action.payload);
      state.errors = [];
    },
    [createImage.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // Update image
    [updateImage.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [updateImage.fulfilled]: (state, action) => {
      state.isLoading = false;
      const index = state.data.findIndex(
        (image) => image._id === action.payload._id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.errors = [];
    },
    [updateImage.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },

    // Delete image
    [deleteImage.pending]: (state) => {
      state.isLoading = true;
      state.errors = [];
    },
    [deleteImage.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = state.data.filter(
        (image) => image._id !== action.payload._id
      );
      state.errors = [];
    },
    [deleteImage.rejected]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
    },
  },
});

export default imagesSlice.reducer;
