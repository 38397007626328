import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  items: []
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      state.items.push(payload);
    },
    removeFromCart: (state, { payload }) => {
      state.items = state.items.filter(el => el.property?._id !== payload.propertyId);
    },
    emptyCart: (state) => {
      state.items = [];
    }
  }
});

export const { addToCart, removeFromCart, emptyCart } = cartSlice.actions;

export default cartSlice.reducer;